body {
  background-color: #f5f5f5;
  margin: 0;
  font-family: Arial, sans-serif;
}

[data-theme='dark'] {
  background-color: #121212;
  color: #e0e0e0;
}

[data-theme='dark'] .MuiPaper-root {
  background-color: #424242;
  color: #e0e0e0;
}
